import React, { FC, useEffect, useState } from 'react';

import { Box, SxProps, Theme } from '@mui/material';
import { mergeSxProps } from 'common/theme';
import { getImageBrightness } from 'common/utils';

type Props = {
  src: string;
  sx?: SxProps<Theme>;
};

export const ImageBackground: FC<Props> = ({ src, children, ...props }) => {
  const [isBrightImage, setIsBrightImage] = useState(false);

  useEffect(() => getImageBrightness(src, ({ isBright }) => setIsBrightImage(isBright)), [src]);

  return (
    <Box {...props} sx={mergeSxProps(props.sx, { background: isBrightImage ? '#000' : '#fff' })}>
      {children}
    </Box>
  );
};
