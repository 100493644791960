/* eslint-disable @typescript-eslint/naming-convention */
export enum Routes {
  CommunitySettings = '/community-settings',
  CommunityCreator = '/community-wizard',
  EventWizard = '/events/wizard',
  MemberSetup = '/member-setup',
  MyCommunity = '/my-community',
  MyConnections = '/my-connections',
  MyEvents = '/my-events',
  MyEventsAll = '/all',
  MyEventsUpcoming = '/upcoming',
  MyEventsPast = '/past',
  VideoCall = '/video-call',
  Backstage = '/backstage',
  WaitingRoom = '/waiting-room',
  Broadcast = '/broadcast',
  CallFeedback = '/call-feedback',
}

export enum AppRoutes {
  CommunityCreator = '/community-wizard',
  CommunitySettings = '/community-settings',
  EventBackstage = '/event-room/:id/backstage',
  EventBroadcast = '/event-room/:id/broadcast',
  EventVideoCall = '/event-room/:id/video-call',
  EventWaitingRoom = '/event-room/:id/waiting-room',
  /** @deprecated use **EventDetails** */
  EventDetails_old = '/event-details/:id',
  EventDetails = '/my-events/details/:id',
  EventEnd = '/event-end/:id',
  EventPublic = '/event-public/:id',
  EventRoom = '/event-room/:id',
  EventCallFeedback = '/event-room/:id/call-feedback',
  EventReport = '/event-details/:id/report',
  PublicEventReport = '/public/event/:id/report',
  JoinEvent = '/event-details/:id/join',
  EventRoomSpeaker = '/event-room-speaker/:id/:token',
  EventWizard = '/events/wizard/:id?',
  InsightsReports = '/insights-reports',
  InsightsReportsEvents = '/events',
  InsightsReportsSurveys = '/surveys',
  InsightsReportsWizard = '/insights-reports/wizard',
  InsightsReportsWizardEvents = '/insights-reports/wizard/events',
  InsightsReportsWizardSurveys = '/insights-reports/wizard/surveys',
  InsightsReportsEventById = '/insights-reports/events/:id',
  InsightsReportSurveyById = '/insights-reports/surveys/:id',

  MemberSetup = '/member-setup', // If member setup url change, BE update is required to Cronofy redirect
  MyCommunity = '/my-community',
  MyConnections = '/my-connections',
  ConnectionDetails = '/my-connections/details',
  MyEvents = '/my-events',
  MyEventsUpcoming = '/my-events/upcoming',

  /** @deprecated use **Account** */
  MyProfile = '/user-profile',
  CommunityProfile = '/community-profile',
  Organization = '/org',
  JoinOrganization = '/org/:slug/:organizationId/join',
  RegisterToCommunity = '/invitation/:communityId',
  Surveys = '/surveys',
  SurveyWizard = '/surveys/wizard/:id?',
  SurveyWizardCreate = '/surveys/wizard/create',
  SurveyPublic = '/survey-public/:id',
  SurveyDetails = '/survey-details/:id',
  SurveyReport = '/survey-details/:id/report',
  PublicSurveyReport = '/public/survey/:id/report',
  SurveyQuestionnaire = '/survey-questionnaire/:id',
  Settings = '/settings',
  Account = '/account',
  // TODO Refactor AppRoute to constant and merge with modules routes
  Natters = '/natters',
  NattersList = '/natters/list',
  NattersWizard = '/natters/wizard/:id?',
  NatterDetails = '/natters/:id',
  NatterPostSurvey = '/natters/:id/post-survey',
  NatterCancel = '/natters/:id/cancel',
  TechCheck = '/tech-check',
  CustomLogin = '/login/:slug',
}

export type EventRouteParams = {
  id?: string;
  hash?: string;
  register?: string;
};

export type WithRedirectUrl<T extends Record<string, unknown> = {}> = {
  redirectUrl: string;
} & T;

export type WithCode<T extends Record<string, unknown> = {}> = {
  code: string;
} & T;

export type InsightsReportRouteParams = {
  id: string;
};
