import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { BlurLevel, MediaSettingsState } from 'domain/event';

import { agoraActions } from '../agora';
import { userActions } from './actions';
import { UserState } from './types';

export const initialUserState: UserState = {
  mediaDevicesLoaded: false,
  mediaDevices: [],
  mediaSettings: {
    audioInputDeviceId: '',
    audioOutputDeviceId: '',
    videoDeviceId: '',
    blurLevel: Number(localStorage.getItem('blurLevel') ?? 0) as BlurLevel,
  },
  userSettings: {
    isAudioEnabled: true,
    isVideoEnabled: true,
  },
  isMediaSettingsOpen: false,
  isMutedOnSystemLevel: false,
  hasMediaAccess: undefined,
  microphoneIssueDetected: false,
  isOnline: true,
};

export const userSlice = createSlice({
  name: 'user',
  initialState: initialUserState,
  reducers: {
    setAudioEnabled: (state, action: PayloadAction<boolean>) => {
      state.userSettings.isAudioEnabled = action.payload;
    },
    setVideoEnabled: (state, action: PayloadAction<boolean>) => {
      state.userSettings.isVideoEnabled = action.payload;
    },
    setMediaSettings: (state, action: PayloadAction<Partial<MediaSettingsState>>) => {
      state.mediaSettings = {
        ...state.mediaSettings,
        ...action.payload,
      };
    },
    setMediaSettingsIsOpen: (state, action: PayloadAction<boolean>) => {
      state.isMediaSettingsOpen = action.payload;
    },
    setMutedOnSystemLevel: (state, action: PayloadAction<boolean>) => {
      state.isMutedOnSystemLevel = action.payload;
    },
    setMediaAccess: (state, action: PayloadAction<boolean>) => {
      state.hasMediaAccess = action.payload;
    },
    setDevicesLoaded: (state, action: PayloadAction<boolean>) => {
      state.mediaDevicesLoaded = action.payload;
    },
    setVideoDeviceId: (state, action: PayloadAction<string>) => {
      state.mediaSettings.videoDeviceId = action.payload;
    },
    setAudioInputDeviceId: (state, action: PayloadAction<string>) => {
      state.mediaSettings.audioInputDeviceId = action.payload;
    },
    setMicrophoneIssueDetected: (state, action: PayloadAction<boolean>) => {
      state.microphoneIssueDetected = action.payload;
    },
    setIsOnline: (state, action: PayloadAction<boolean>) => {
      state.isOnline = action.payload;
    },
    setAudioDisabled: (state) => {
      state.userSettings.isAudioEnabled = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userActions.navigator.mediaDevices.devicesFound, (state, action) => {
        state.mediaDevices = action.payload;
      })
      .addCase(userActions.mediaSettings.settingsUpdated, (state, action) => {
        const { audioInputDeviceId, audioOutputDeviceId, blurLevel, isAudioEnabled, isVideoEnabled, videoDeviceId } =
          action.payload;

        state.mediaSettings = {
          audioInputDeviceId,
          audioOutputDeviceId,
          blurLevel,
          videoDeviceId,
        };

        state.userSettings = {
          isAudioEnabled,
          isVideoEnabled,
        };
      })
      .addCase(agoraActions.audioTrack.toggling.failed, (state) => {
        state.userSettings.isAudioEnabled = !state.userSettings.isAudioEnabled;
      })
      .addCase(agoraActions.videoTrack.toggling.failed, (state) => {
        state.userSettings.isVideoEnabled = !state.userSettings.isVideoEnabled;
      });
  },
});
