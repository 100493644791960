import { mapObjectWithIdListToIds } from 'common/utils';
import { differenceInMinutes } from 'date-fns';
import { AgoraProxyMode } from 'domain/event';
import { EventWizardFormSerializedValues } from 'pages/EventWizard/validation/schema';
import { CreateUpdateEventForApi } from 'store/apis/event';

export const mapEventWizardFormValuesToApi = ({
  eventVideos,
  eventRecordingEnabled,
  schedule,
  ...data
}: EventWizardFormSerializedValues & { communityId?: number; relatedImageIds?: number[] }): Omit<
  CreateUpdateEventForApi,
  'communityId'
> & {
  communityId?: number;
} => ({
  name: data.name,
  communityId: data.communityId,
  pinEnabled: data.pinEnabled,
  description: data.description || '',
  // TODO: below code will be updated in https://natterco.atlassian.net/browse/N2-2742
  startTime: schedule[0].startTime,
  eventDuration: differenceInMinutes(new Date(schedule[0].endTime), new Date(schedule[0].startTime)),
  relatedImageIds: data.relatedImageIds || [],
  relatedVideoIds: mapObjectWithIdListToIds(eventVideos),
  agoraProxyMode: AgoraProxyMode.Automatic,
  agoraCloudRecordingEnabled: eventRecordingEnabled,
  hostIds: data.hosts?.map(({ accountId }) => accountId) ?? [],
});
