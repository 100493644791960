import { createSelector } from '@reduxjs/toolkit';
import { MediaTrackState } from 'domain/Broadcast';
import { inRange } from 'lodash';
import { RootState } from 'store';

import { selectCurrentEventPhase } from '../event/selectors';
import { EventPhase } from '../event/types';
import { NETWORK_QUALITY_BOTTOM_THRESHOLD, NETWORK_QUALITY_TOP_THRESHOLD } from './constants';
import { NetworkErrorKey } from './types';

const selectAllAgoraState = (state: RootState) => state.agora;

export const selectIsJoinedToAgora = createSelector(selectAllAgoraState, (state) => state.isJoined);

const selectNetworkQuality = createSelector(selectAllAgoraState, (state) => state.networkQuality);

const selectAudioTrack = createSelector(selectAllAgoraState, (state) => state.audioTrack);
const selectVideoTrack = createSelector(selectAllAgoraState, (state) => state.videoTrack);

export const selectAudioTrackState = createSelector(selectAudioTrack, (audioTrack) => audioTrack.state);
export const selectVideoTrackState = createSelector(selectVideoTrack, (videoTrack) => videoTrack.state);

export const selectAudioTrackError = createSelector(selectAudioTrack, (audioTrack) => audioTrack.error);
export const selectVideoTrackError = createSelector(selectVideoTrack, (videoTrack) => videoTrack.error);

export const selectIsAudioTrackReadyToBeCreated = createSelector(selectAudioTrackError, (error) => error === null);
export const selectIsVideoTrackReadyToBeCreated = createSelector(selectVideoTrackError, (error) => error === null);

export const selectCurrentCameraDeviceId = createSelector(selectVideoTrack, (videoTrack) => videoTrack.currentDeviceId);

export const selectCurrentMicrophoneDeviceId = createSelector(
  selectAudioTrack,
  (audioTrack) => audioTrack.currentDeviceId
);

const selectAgoraRole = createSelector(selectAllAgoraState, (state) => state.role);
export const selectIsAgoraHost = createSelector(selectAgoraRole, (role) => role === 'host');

export const selectIsAudioTrackReadyToPublish = createSelector(
  selectIsJoinedToAgora,
  selectAudioTrack,
  (isJoined, audioTrack) => isJoined && audioTrack.state === MediaTrackState.Enabled
);
export const selectIsVideoTrackReadyToPublish = createSelector(
  selectIsJoinedToAgora,
  selectVideoTrack,
  (isJoined, videoTrack) => isJoined && videoTrack.state === MediaTrackState.Enabled
);

export const selectShouldPublishAudioTrackForBroadcast = createSelector(
  selectIsAudioTrackReadyToPublish,
  selectIsAgoraHost,
  (isReady, isAgoraHost) => isReady && isAgoraHost
);
export const selectShouldPublishVideoTrackForBroadcast = createSelector(
  selectIsVideoTrackReadyToPublish,
  selectIsAgoraHost,
  (isReady, isAgoraHost) => isReady && isAgoraHost
);

export const selectConnectionQualityMessageKey = createSelector(
  selectIsJoinedToAgora,
  selectCurrentEventPhase,
  selectAgoraRole,
  selectNetworkQuality,
  (isJoined, eventPhase, role, networkQuality) => {
    if (!isJoined) {
      return null;
    }

    const sum = networkQuality.reduce(
      (acc, current) => ({
        downlinkNetworkQuality: acc.downlinkNetworkQuality + current.downlinkNetworkQuality,
        uplinkNetworkQuality: acc.uplinkNetworkQuality + current.uplinkNetworkQuality,
      }),
      { downlinkNetworkQuality: 0, uplinkNetworkQuality: 0 } as {
        downlinkNetworkQuality: number;
        uplinkNetworkQuality: number;
      }
    );

    const isDownlinkConnectionUnstable = inRange(
      sum.downlinkNetworkQuality,
      NETWORK_QUALITY_BOTTOM_THRESHOLD,
      NETWORK_QUALITY_TOP_THRESHOLD + 1
    );
    const isUplinkConnectionUnstable = inRange(
      sum.uplinkNetworkQuality,
      NETWORK_QUALITY_BOTTOM_THRESHOLD,
      NETWORK_QUALITY_TOP_THRESHOLD + 1
    );

    const uplinkConnectionMatters =
      (eventPhase === EventPhase.Broadcast && role === 'host') || eventPhase === EventPhase.OneOnOne;

    if (uplinkConnectionMatters && isUplinkConnectionUnstable && isDownlinkConnectionUnstable) {
      return NetworkErrorKey.UnstableConnection;
    }

    if (uplinkConnectionMatters && isUplinkConnectionUnstable) {
      return NetworkErrorKey.UnstableUplinkConnection;
    }

    if (isDownlinkConnectionUnstable) {
      return NetworkErrorKey.UnstableConnection;
    }

    return null;
  }
);
