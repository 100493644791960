import { createAction } from '@reduxjs/toolkit';
import type { NetworkQuality } from 'agora-rtc-react';
import { MediaTrackErrorKey } from 'devices';

export const agoraActions = {
  joined: createAction('[Agora] joined'),
  left: createAction('[Agora] left'),
  audioTrack: {
    cleanedUp: createAction('[Agora] audio track cleaned up'),
    creation: {
      completed: createAction('[Agora] audio track creation completed'),
      failed: createAction<MediaTrackErrorKey>('[Agora] audio track creation failed'),
    },
    deviceChange: {
      started: createAction('[Agora] audio track device change started'),
      completed: createAction<string>('[Agora] audio track device change completed'),
      failed: createAction('[Agora] audio track device change failed'),
    },
    published: createAction('[Agora] audio track published'),
    toggling: {
      started: createAction<boolean>('[Agora] audio track toggling started'),
      completed: createAction('[Agora] audio track toggling completed'),
      failed: createAction('[Agora] audio track toggling failed'),
    },
  },
  videoTrack: {
    cleanedUp: createAction('[Agora] video track cleaned up'),
    creation: {
      completed: createAction('[Agora] video track creation completed'),
      failed: createAction<MediaTrackErrorKey>('[Agora] video track creation failed'),
    },
    deviceChange: {
      started: createAction('[Agora] video track device change started'),
      completed: createAction<string>('[Agora] video track device change completed'),
      failed: createAction('[Agora] video track device change failed'),
    },
    published: createAction('[Agora] video track published'),
    toggling: {
      started: createAction<boolean>('[Agora] video track toggling started'),
      completed: createAction('[Agora] video track toggling completed'),
      failed: createAction('[Agora] video track toggling failed'),
    },
  },
  networkQuality: {
    changed: createAction<NetworkQuality>('[Agora] network quality changed'),
  },
  demoted: createAction('[Agora] demoted'),
  promoted: createAction('[Agora] promoted'),
  unpublished: createAction('[Agora] unpublished'),
  cleanedUpAfterBroadcast: createAction('[Agora] cleaned up after broadcast'),
  cleanedUpAfterNattering: createAction('[Agora] cleaned up after nattering'),
};
