import { userSlice } from './userSlice';

export const {
  setAudioEnabled,
  setVideoEnabled,
  setMediaSettings,
  setMediaSettingsIsOpen,
  setMutedOnSystemLevel,
  setMediaAccess,
  setDevicesLoaded,
  setVideoDeviceId,
  setAudioInputDeviceId,
  setMicrophoneIssueDetected,
  setIsOnline,
} = userSlice.actions;
